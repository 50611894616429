import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';

import '../style/footer.css';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <Grid columns={2} verticalAlign="middle" stackable>
          <Grid.Column textAlign="left">
            <Header as="h5">&copy; 2023 ARI HAUBEN</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <a style={{ color: "inherit" }} href="https://www.instagram.com/arihaubenart/">
              <Icon name="instagram" size="large" />
            </a>
            <a style={{ color: "inherit" }} href="https://www.facebook.com/Ari-Haubens-Art-261456753885496/">
              <Icon name="facebook" size="large" />
            </a>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default Footer;