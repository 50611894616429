import React from 'react';
import { Link } from 'gatsby';
import { Menu, Header, Image, Icon, Label } from 'semantic-ui-react';

import '../style/navigation.css';
import CartContext from '../components/CartContext';
import { toHyphenCase } from '../utils/comm';

import SignatureStatic from '../data/graphics/signature-static.png';

const menuItems = ['gallery', 'in the wild', 'contact', 'about','happenings'];

const activeStyle = {
  borderBottom: "solid 2px #787878",
};

export default ({ location }) => {
  return (
    <div className="navigation-container desktop-nav">
      <Menu borderless>
        <Menu.Menu position="left">
          <Menu.Item className="navigation-signature" as={Link} to="/gallery/">
            <Image src={SignatureStatic} size="small"/>
          </Menu.Item>
          <Menu.Item className="navigation-title">
            <Header as="h3">ARI HAUBEN</Header>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position="right">
          {menuItems.map(item => (
            <Menu.Item key={item}>
              <Link to={`/${toHyphenCase(item)}/`} activeStyle={activeStyle}>
                <Header as="h5">{item.toUpperCase()}</Header>
              </Link>
            </Menu.Item>
          ))}
          {/*<CartContext.Consumer>*/}
            {/*{*/}
              {/*({ cartItems }) => (*/}
                {/*<Menu.Item>*/}
                  {/*<Link to={`/cart`}>*/}
                    {/*<Icon.Group size="large">*/}
                      {/*<Icon name="cart" color="black" />*/}
                      {/*<Label color="red" circular floating>*/}
                        {/*{cartItems.length}*/}
                      {/*</Label>*/}
                    {/*</Icon.Group>*/}
                  {/*</Link>*/}
                {/*</Menu.Item>*/}
              {/*)*/}
            {/*}*/}
          {/*</CartContext.Consumer>*/}
        </Menu.Menu>
      </Menu>
    </div>
  )
}