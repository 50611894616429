import React from 'react';
import Helmet from 'react-helmet';

import Navigation from './Navigation';
import Footer from './Footer';
import CartContent from './CartContext';

import '../style/global.css';
import 'semantic-ui-less/semantic.less';


export default class extends React.Component {
  constructor(props) {
    super(props);


    this.state = { cartItems: [] };

    this.onAddCartItem = this.onAddCartItem.bind(this);
    this.onRemoveCartItem = this.onRemoveCartItem.bind(this);
    this.onClearCart = this.onClearCart.bind(this);
  }

  componentDidMount() {
    this.setState({ cartItems: JSON.parse(window.sessionStorage.getItem("cartItems")) || [] })
  }

  onRemoveCartItem(index) {
    console.log(index)
    const { cartItems } = this.state;
    const newCartItems = [...cartItems.slice(0, index), ...cartItems.slice(index +  1)];

    window.sessionStorage.setItem("cartItems", JSON.stringify(newCartItems));

    this.setState({ cartItems: newCartItems })
  }

  onClearCart() {
    window.sessionStorage.setItem("cartItems", JSON.stringify([]));

    this.setState({ cartItems: [] })
  }

  onAddCartItem(item) {
    const { cartItems } = this.state;

    const newCartItems = [...cartItems, item];

    window.sessionStorage.setItem("cartItems", JSON.stringify(newCartItems));

    this.setState({ cartItems: newCartItems })
  }

  render() {
    const { children } = this.props;
    const { cartItems } = this.state;
    const contextValues = {
      cartItems,
      onRemoveCartItem: this.onRemoveCartItem,
      onAddCartItem: this.onAddCartItem,
      onClearCart: this.onClearCart
    };

    return (
      <div>
        <Helmet
          title="Ari Hauben Art"
          meta={[
            { name: 'description', content: 'The official site of Boston-based contemporary artist, Ari Hauben.' },
          ]}
        >
          <script type='text/javascript'> var _d_site = _d_site || 'AFCB58EB5EB257F759613C9C'; </script>
          <script src='https://widget.privy.com/assets/widget.js'></script>
          <script>
            {`window['_fs_debug'] = false;
            window['_fs_host'] = 'fullstory.com';
            window['_fs_org'] = 'NMW8Z';
            window['_fs_namespace'] = 'FS';
            (function(m,n,e,t,l,o,g,y){
            if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
            g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
            o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_host+'/s/fs.js';
            y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
            g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
            g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
            g.log = function(a,b) { g("log", [a,b]) };
            g.consent=function(a){g("consent",!arguments.length||a)};
            g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
            g.clearUserCookie=function(){};
          })(window,document,window['_fs_namespace'],'script','user');`}
          </script>
          <script src="https://fast.wistia.com/embed/medias/fhaimg0e2j.jsonp" async />
          <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
        </Helmet>
        <CartContent.Provider value={contextValues}>
          <Navigation />
          <div className="page-container">{children}</div>
          <Footer />
        </CartContent.Provider>
      </div>
    );
  }
}
